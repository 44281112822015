import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  ViewEncapsulation,
} from "@angular/core";
import * as moment from "moment";

import { MatDialog } from "@angular/material/dialog";
import {
  EventInput,
  Calendar,
  CalendarOptions,
  EventApi,
} from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import { FullCalendarComponent } from "@fullcalendar/angular";
import frLocale from "@fullcalendar/core/locales/fr";
import momentPlugin from "@fullcalendar/moment";
import momentTimezonePlugin from "@fullcalendar/moment-timezone";
import { SlotType } from "src/app/enums/SlotType.enum";
import { BookingSlotsService } from "src/app/services/booking-slots.service";
import { BookingSlot } from "src/app/models/booking-slot";
import { DoctorService } from "src/app/services/doctor.service";
import { Doctor } from "src/app/models/doctor";
import { BookingDialogComponent } from "src/app/components/booking-dialog/booking-dialog.component";
import { SnackBarService } from "src/app/services/snack-bar.service";
import { ErrorsHandler } from "src/app/services/error-handler.service";
import { TranslateService } from "@ngx-translate/core";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import Swal from "sweetalert2";
import { BookingHelper } from "src/app/helpers/booking_helper";
import { StateType } from "src/app/enums/stateType.enum";
import { BookingType } from "src/app/enums/bookingType.enum";
import { GlobalService } from "src/app/services/global.service";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import { of } from "rxjs";
import * as Sentry from "@sentry/angular-ivy";

@Component({
  templateUrl: "./calendar.component.html",
  styleUrls: ["./calendar.component.scss"],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: "fr" }],
  encapsulation: ViewEncapsulation.None,
})
export class CalendarComponent implements OnInit, AfterViewInit {
  selectedDate = new Date();
  year: any;
  DayAndDate: string;
  appointmentTypes = SlotType;
  bookingTypes = BookingType;
  clinic: boolean;
  @ViewChild("calendar", { static: false }) fullcalendar: FullCalendarComponent;
  clinic_resources: any;
  fullCalendarApi: Calendar;
  calendarConfig: CalendarOptions;
  toConfirmBookingSlots: BookingSlot[] = [];

  doctorBookingTypes: SlotType[] = [];

  isPortletCollapse: boolean = false;
  arrowDirection: boolean = true;
  currentDoctor: Doctor;
  timeIntervals = [
    { value: "15", viewValue: "15m" },
    { value: "20", viewValue: "20m" },
    { value: "30", viewValue: "30m" },
    { value: "60", viewValue: "60m" },
  ];

  slotMinTime: number;
  slotMaxTime: number;
  startTimeHours = [5, 6, 7, 8, 9, 10];
  endTimeHours = [17, 18, 19, 20, 21, 22, 23];

  // We use {{isMobile}} and {{initialView}} it to check if the user is no mobile or not: Default (False)
  isMobile: boolean = false;
  initialView: string;
  PressDelay: number = 0;

  togglePortlet(key) {
    if (key == "down") {
      this.arrowDirection = true;
    } else {
      this.arrowDirection = false;
    }
    this.isPortletCollapse = !this.isPortletCollapse;
  }

  constructor(
    private dialog: MatDialog,
    private bookingSlotsService: BookingSlotsService,
    private snackbar: SnackBarService,
    private translate: TranslateService,
    private bookingHelper: BookingHelper,
    private errorHandler: ErrorsHandler,
    private _adapter: DateAdapter<any>,
    private globalService: GlobalService,
    private doctorService: DoctorService
  ) {
    //Check the device if Mobile then show the calendar by day, otherwise show it by week.
    this.checkDevice();
  }

  ngOnInit(): void {
    this.globalService.showSpinner();
    this._adapter.setLocale("fr");
    this.doctorService.currentDoctor$.subscribe((currentDoctor) => {
      if (currentDoctor) {
        this.currentDoctor = currentDoctor;
        if(this.currentDoctor.clinic && this.currentDoctor.clinic_resources){
          this.clinic_resources = this.currentDoctor.clinic_resources.map(
            (data) => ({ id: data._id, title: data.name })
          );
        }
        else
        {
          this.clinic_resources = [{ id: this.currentDoctor.id, title: (this.currentDoctor.first_name + " " + this.currentDoctor.last_name) }]
        }
        this.slotMinTime = currentDoctor.opening_hour;
        this.slotMaxTime = currentDoctor.closing_hour;
        this.clinic = this.currentDoctor.clinic;
        this.setupCalendarConfig(currentDoctor);
        moment.tz.setDefault(currentDoctor.time_zone); // force the correct doctor's timezone
      }
    });
    this.getToConfirmBookingSLots();
    this.getDoctorBookingsTypes();

    // TODO
    // this.subscribeToRealTimeCalendarEvents()
  }

  private fetchEvents(dateInfo) {
    const filters = `state=confirmed_doctor,confirmed_assistant,awaiting_payment&start=${dateInfo.start?.toISOString()}&end=${dateInfo.end?.toISOString()}&timeZone=${dateInfo.timeZone}`;
    return this.bookingSlotsService.get(filters).toPromise();
  }

  clinicHasOneResource()
  {
    return this.clinic && this.currentDoctor.clinic_resources.length == 1;
  }

  get headerToolbarView()
  {
    if(this.clinicHasOneResource())
    {
      return "dayGridMonth,resourceTimeGridWeek,resourceTimeGridDay,list";
    }
    else if (this.clinic)
    {
      return "resourceTimeGridDay"
    }
    else
    {
      return 'dayGridMonth,timeGridWeek,timeGridDay,list'
    }
  }

  setupCalendarConfig(doctor: Doctor): void {
    this.globalService.hideSpinner();
    const todayDate = moment().startOf("day");
    const TODAY = todayDate.format("YYYY-MM-DD");
    const averageSlotDuration = doctor.average_booking_duration;

    const self = this;
    const locale = this.currentDoctor.locale;
    
    let initialView;
    if (!this.clinic) 
    {
      initialView = this.initialView;
    } 
    else if (this.clinicHasOneResource())
    {
      initialView = "resourceTimeGridWeek";
    } 
    else
    {
      initialView = "resourceTimeGridDay";
    }
    
    this.calendarConfig = {
      schedulerLicenseKey: "CC-Attribution-NonCommercial-NoDerivatives",
      plugins: [
        dayGridPlugin,
        listPlugin,
        interactionPlugin,
        timeGridPlugin,
        momentPlugin,
        momentTimezonePlugin,
        resourceTimeGridPlugin,
      ],
      themeSystem: 'bootstrap',
      titleFormat: function (date) {
        const startDate = moment(date.start).locale(locale);
        const endDate = moment(date.end).locale(locale);
    
        const formattedStartDate = startDate.format('D');
        const formattedEndDate = endDate.format('D');
        const monthYear = startDate.format('MMMM YYYY');

        const isSameMonthYear = startDate.isSame(endDate, 'month') && startDate.isSame(endDate, 'year');

        let title = self.clinicHasOneResource() ? '(' + self.clinic_resources[0].title + ') | ' : ''

        if (formattedStartDate == formattedEndDate) {
          title += formattedStartDate;
        } else {
          title += `${formattedStartDate} – ${formattedEndDate}`;
        }
        title += isSameMonthYear ? ` ${monthYear}` : ` ${startDate.format('MMMM')} – ${endDate.format('MMMM')} ${endDate.format('YYYY')}`;

        return title;
      },
      headerToolbar: {
        left: "prev,next,today",
        center: "title",
        right: this.headerToolbarView,
      },
      height: 800,
      contentHeight: 780,
      longPressDelay: this.PressDelay,
      aspectRatio: 3,
      nowIndicator: true,
      views: {},
      initialView: initialView,
      initialDate: TODAY,
      slotMinTime: this.slotMinTime + ":00",
      slotMaxTime: this.slotMaxTime + ":00",
      timeZone: doctor.time_zone,
      slotDuration: `00:${averageSlotDuration}:00`,
      editable: false,
      dayMaxEventRows: true,
      navLinks: true,
      selectable: true,
      locales: [frLocale],
      locale: doctor.locale,
      eventTimeFormat: "H:mm",
      events: this.fetchEvents.bind(this),
      eventSourceFailure: (obj) => {
        // TODO show an error message
      },
      // Transform bookings attributes to proper fullcalendar objects
      eventDataTransform: (eventData: any) => {
        let x: EventInput = this.transformBookingSlotToEvent(eventData)
        return x;
      },
      dayCellDidMount : (event) => {
        if ( ["timeGridWeek", "resourceTimeGridWeek", "timeGridDay", "resourceTimeGridDay"].includes(event.view.type) &&
          (event.isFuture || event.isToday))
        {
          const subElement = (event.el.firstChild as HTMLElement).firstChild as HTMLElement;
          const resource = this.getCurrentResource(event) // clinic resource or doctor
          const available = (resource && resource.available_days?.length > 0) ? resource.available_days[(event.dow + 6) % 7].available : false
          const fc_daygrid_body = this.findParentByClass(event.el, "fc-daygrid-body");
          const fc_timegrid_body = this.findParentByClass(event.el, "fc-timegrid-body");
          if (fc_daygrid_body)
          {
            event.el.style.verticalAlign = "middle";
            subElement.className += " cancel_day";
            subElement.style.top = this.clinicHasOneResource() ? '12px' : '6px';
            if (resource?.available_days && resource.available_days.length > 0)
            {
              if(available) subElement.innerHTML = '<span class="cancel-day-btn fa fa-times-circle"></span>';
              if(!available) subElement.innerHTML = '<span title="' + this.translate.instant("connect.globals.no_availabilities_for_this_day") 
                + '" class="cancel-day-btn fa fa-question-circle"></span>';
            }
          }
          if(!available && (fc_timegrid_body || fc_daygrid_body))
          {
            if (this.currentDoctor.clinic) event.el.classList.remove('fc-day-today');
            event.el.classList.add('unavailable-day');
          };
        }
      },
      eventDidMount : (event) => {
        if (
          event.event.extendedProps.type === BookingType.VIDEO &&
          event.event.extendedProps.state !== StateType.AWAITING_PAYMENT
        ) {
          event.el.querySelector(".fc-event-title").innerHTML =
            event.event.title +
            '<span class="label label-danger" style="position: absolute; top: 0; right: 0; font-size: 1em;"><i class="fa fa-video slot-event-background"></i></span>';
        }
        if (event.event.extendedProps.type === BookingType.HOME) {
          event.el.querySelector(".fc-event-title").innerHTML =
            event.event.title +
            '<span class="label label-danger" style="position: absolute; top: 0; right: 0; font-size: 1em;"><i class="fa fa-home slot-event-background"></i></span>';
        }
        if (event.event.extendedProps && event.event.extendedProps.partner) {
          event.el.querySelector(".fc-event-title").innerHTML =
            event.event.title +
            `<span class="partner_slot">${event.event.extendedProps.partner}</span>`;
        }
        if (
          event.event.extendedProps.type === BookingType.VIDEO &&
          event.event.extendedProps.state === StateType.AWAITING_PAYMENT
        ) {
          event.el.querySelector(".fc-event-title").innerHTML =
            event.event.title +
            '<span class="label label-danger" style="position: absolute; top: 0; right: 0; font-size: 1em;"><i class="fa fa-exclamation-circle slot-event-background"></i><i class="fa fa-video slot-event-background"></i></span>';
        }
        if (event.event.extendedProps.patient_didnt_showup) event.el.classList.add('didntshowup');
      },
      select: (arg) => {
        const currentView = arg.view.type;
        if (currentView === 'dayGridMonth') {
          arg.allDay = false; // Force allDay to false in month view to show the appointement 
        }
        if (arg.allDay) {
          const isValidDate = arg.start.setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0);
          isValidDate ? this.toggleDayAvailability(arg.startStr, this.getResourceId(arg)) : this.snackbar.open(this.translate.instant("connect.calendar.invalid_slot"));
        }
        else 
        {
          arg.jsEvent.preventDefault();
          const time_interval = moment.utc(moment(arg.end, "HH:mm:ss").diff(moment(arg.start, "HH:mm:ss"))).format("mm");
          let selection_type = "multiple_slot";
          if (arg.start < new Date()) {
            if (this.fullCalendarApi) {
              this.fullCalendarApi.unselect();
            }
          } else {
            if (doctor.average_time_interval == Number(time_interval)) {
              selection_type = "single_slot";
            }
            const resourceId = this.getResourceId(arg);
            this.openBookingModal({
              bookingSlot: this.transformEventToBookingSlot(arg),
              selection_type: selection_type,
              resourceId: resourceId
            });
          }
        }
      },
      resources: this.clinic_resources,
      eventDrop: (arg) => {
        // if the new start time is in the past
        if (arg.event.start < new Date()) {
          arg.revert();
        } else {
          this.updateBookingSlotFromCalendar(arg);
        }
      },
      eventResize: (arg) => {
        // if the new start time is in the past
        if (arg.event.start < new Date()) {
          arg.revert();
        } else {
          this.updateBookingSlotFromCalendar(arg);
        }
      },
      eventClick: (arg) => {
        this.openBookingModal({
          bookingSlot: {...this.transformEventToBookingSlot(arg.event), resourceId: arg.event._def.resourceIds[0]},
        });
      },
    };
  }

  getCurrentResource(event)
  {
    return this.currentDoctor.clinic ? this.currentDoctor.available_days_for_clinic_resources?.find(resource => resource.id == event.resource._resource.id ) : this.currentDoctor
  }

  getResourceId(arg:any)
  {
    if(this.clinic)
    {
      return this.clinicHasOneResource() ? this.currentDoctor.clinic_resources[0]._id : arg.resource._resource.id
    }
    else
    {
      return null
    }
  }

  onDepartementChnage(event) {
    this.clinic_resources = this.clinic_resources.filter(
      (data) => data.doctor_speciality_id === event.target.value
    );
  }

  findParentByClass(el, className)
  {
    for (let i = 0; i < 10; i++) {
      el = el.parentNode;
      if (el.classList.contains(className)) {
        return true;
      }
    }
  }

  getToConfirmBookingSLots() {
    this.bookingSlotsService.toConfirm().subscribe({
      next: (bookingsToConfirm: BookingSlot[]) => {
        if (this.toConfirmBookingSlots?.length < bookingsToConfirm?.length) {
          this.toConfirmBookingSlots = bookingsToConfirm;
        }
      },
      error: (error) => {
        Sentry.captureException(error);
      }
    });
  }

  onSelect(event, _moment = moment) {
    this.selectedDate = event;
    if (this.fullcalendar) {
      this.fullcalendar.getApi().gotoDate(moment(event).format(moment.HTML5_FMT.DATE));
    }
  }

  cancelBookingSlot(bookingSlot) {
    const text = this.bookingHelper.getCancelMessage(bookingSlot);
    Swal.fire({
      heightAuto: false,
      focusConfirm: false,
      title: this.translate.instant("connect.globals.confirmation_box_title"),
      text: text,
      icon: this.translate.instant("connect.globals.confirmation_box_type"),
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#808285",
      confirmButtonText: this.translate.instant(
        "connect.globals.cancel_booking"
      ),
      cancelButtonText: this.translate.instant("connect.globals.cancel"),
      willOpen: () => {
        document.querySelector('app-root')?.classList.add('no-scroll');
      },
      willClose: () => {
        document.querySelector('app-root')?.classList.remove('no-scroll');
      }
    }).then((result) => {
      if (result.value) {
        Swal.fire(
          this.translate.instant(
            "connect.appointment_table.booking_slot_cancelled"
          )
        );
        return this.bookingSlotsService.cancel(bookingSlot.id).subscribe(
          (data) => {
            this.getToConfirmBookingSLots();
            this.snackbar.open(
              this.translate.instant("connect.calendar.booking_cancelled")
            );
          },
          (error) => this.errorHandler.handleError("Error occurred")
        );
      }
    });
  }

  toggleDayAvailability(current_day: string, resourceId: string) {
    this.globalService.showSpinner();
    this.bookingSlotsService.toggleDayAvailability(current_day,resourceId).subscribe(
      (data: any) => {
        if (data)
          this.snackbar.open(
            this.translate.instant("connect.calendar." + data.message)
          );
        this.refreshCalendar();
        this.globalService.hideSpinner();
      },
      (error) => {
        this.snackbar.open(
          this.translate.instant("connect.calendar." + error.error.message)
        );
        this.globalService.hideSpinner();
      }
    );
  }

  confirmBookingSlot(bookingSlot) {
    const text = this.bookingHelper.getCancelMessage(bookingSlot);
    
    if (this.doctorService.current.clinic && !this.clinicHasOneResource() && (bookingSlot.resourceId == null || bookingSlot.resourceId == "")) {
      Swal.fire({
        heightAuto: false,
        focusConfirm: false,
        text: this.translate.instant("connect.globals.edit_appointement_add_doctor"),
        icon: "info",
        willOpen: () => {
          document.querySelector('app-root')?.classList.add('no-scroll');
        },
        willClose: () => {
          document.querySelector('app-root')?.classList.remove('no-scroll');
        }
      });
      return;
    }
  
    const updateObservable = this.clinicHasOneResource() ? 
      this.bookingSlotsService.update({
        ...bookingSlot,
        clinic_resource_id: this.currentDoctor.clinic_resources[0]._id,
      }) : 
      of(null);
  
    updateObservable.subscribe({
      next: () => {
        Swal.fire({
          heightAuto: false,
          focusConfirm: false,
          title: this.translate.instant("connect.globals.confirmation_box_title"),
          text: text,
          icon: this.translate.instant("connect.globals.confirmation_box_type"),
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#808285",
          confirmButtonText: this.translate.instant("connect.globals.booking_confirm"),
          cancelButtonText: this.translate.instant("connect.globals.cancel"),
          willOpen: () => {
            document.querySelector('app-root')?.classList.add('no-scroll');
          },
          willClose: () => {
            document.querySelector('app-root')?.classList.remove('no-scroll');
          }
        }).then((result) => {
          if (result?.value && bookingSlot?.id) {
            this.bookingSlotsService.confirm(bookingSlot.id).subscribe({
              next: () => {
                this.refreshCalendar();
                this.getToConfirmBookingSLots();
                Swal.fire({
                  heightAuto: false,
                  focusConfirm: false,
                  text: this.translate.instant('connect.calendar.booking_confirmed'),
                  icon: "success"
                });
                this.snackbar.open(this.translate.instant("connect.calendar.booking_confirmed"));
              },
              error: (error) => {
                this.errorHandler.handleError(this.translate.instant("connect.calendar.error_occurred"));
              }
            });
          }
        });
      },
      error: (error) => {
        this.errorHandler.handleError(this.translate.instant("connect.globals.error_occured_message"));
      }
    });
  }

  myDateFilter = (d: Date): boolean => {
    const day = d.getDay();
    return day !== 0 && day !== 6;
  };

  getDoctorBookingsTypes() {
    this.doctorService
      .getBookingsTypes()
      .subscribe((types: SlotType[]) => (this.doctorBookingTypes = types));
  }

  ngAfterViewInit(): void {
    setInterval(() => {
      this.getToConfirmBookingSLots();
      this.refreshCalendar();
    }, 60000); // Update bookings to confirm and calendar events every 1 minute
  }
  changeSlotDuration(averageSlotDuration) {
    averageSlotDuration = Number(averageSlotDuration);
    this.calendarConfig.slotDuration = "00:" + averageSlotDuration + ":00";

    this.doctorService.updateTimeInterval(averageSlotDuration).subscribe(
      (data) => {
        this.snackbar.open(
          this.translate.instant(
            "connect.booking_availability.appointment_category_updated"
          )
        );
      },
      (error) =>
        this.errorHandler.handleError(
          this.translate.instant("connect.globals.error_occured_message")
        )
    );
  }

  updateSlotTimes() {
    this.calendarConfig.slotMinTime = this.slotMinTime + ":00";
    this.calendarConfig.slotMinTime = this.slotMaxTime + ":00";
    this.doctorService.updateTimeSlots(this.slotMinTime, this.slotMaxTime).subscribe(
      (data) => {
        this.snackbar.open(
          this.translate.instant("connect.calendar.updated_settings")
        );
      },
      (error) =>
        this.errorHandler.handleError(
          this.translate.instant("connect.globals.error_occured_message")
        )
    );
  }

  private transformBookingSlotToEvent(bookingSlot: BookingSlot): any {
    const video = bookingSlot.type === BookingType.VIDEO;
    const isBlocking = bookingSlot.slot_type === SlotType.BLOCKING;
    const title = !isBlocking
      ? bookingSlot.user_first_name + " " + bookingSlot.user_last_name
      : "";
    // Only future appointments are editable
    const isEditable = new Date(bookingSlot.starts_at) > new Date();
    const fcEvent : EventInput = {
      id: bookingSlot.id,
      title: title,
      start: bookingSlot.starts_at,
      end: bookingSlot.ends_at,
      classNames: [isBlocking ? "canceled_slot blocked_slot" : "", video ? "video_slot" : ""],
      editable: isEditable,
    };
    // Copy the rest of the attributes
    return Object.assign(fcEvent, bookingSlot);
  }

  private transformEventToBookingSlot(event): BookingSlot {
    const booking = Object.assign({}, event.extendedProps);
    return Object.assign(booking, {
      id: event.id,
      starts_at: event.start,
      ends_at: event.end,
    });
  }

  private updateBookingSlotFromCalendar(arg) {
    this.bookingSlotsService
      .update(this.transformEventToBookingSlot(arg.event))
      .subscribe(
        (success) => {
          // TODO All good, show a confirmation toaster
          this.refreshCalendar();
        },
        (error) => {
          arg.revert();
        }
      );
  }

  private openBookingModal(data = {}) {
    this.dialog
      .open(BookingDialogComponent, {
        width: "515px",
        data: data,
        panelClass: "calendar-dailog",
      })
      .afterClosed()
      .subscribe(() => {
        this.refreshCalendar();
      });
  }

  private refreshCalendar() {
    this.getToConfirmBookingSLots();
    if (this.fullcalendar) {
      this.fullCalendarApi = this.fullcalendar.getApi();
    }
    if (this.fullCalendarApi) {
      this.fullCalendarApi.refetchEvents();
    }
  }

  private checkDevice() {
    //Check the device if Mobile then show the calendar by day, otherwise show it by week.
    // TODO use https://www.npmjs.com/package/ngx-device-detector
    if (window.innerWidth <= 1024) {
      this.isMobile = true;
      if (window.innerWidth <= 425) {
        this.initialView = "timeGridDay";
        this.PressDelay = 300;
      } else {
        this.initialView = "timeGridWeek";
        this.PressDelay = 0;
      }
    } else {
      this.isMobile = false;
      this.initialView = "timeGridWeek";
      this.PressDelay = 0;
    }
  }
  onResize() {
    this.checkDevice();
  }

  // TODO: integrate action cable and implement the
  // private subscribeToRealTimeCalendarEvents() {
  //   App.messages = App.cable.subscriptions.create({
  //     channel: "DoctorChannel",
  //     doctor_id: window.doctor_id
  //   }, {
  //     connected: function() {
  //       this.refreshCalendar();
  //       this.getToConfirmBookingSLots()
  //     },
  //     disconnected: function() {},
  //     received: function(data) {
  //       if (data.domaine === "confirmations") {
  //         this.getToConfirmBookingSLots()
  //       }
  //     }
  //   });
  // }
}
